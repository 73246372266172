import React, { Fragment } from "react";
import contactUsImage from "../../Assets/contact-us.jpg";
import { SecondaryNav } from "../Common/SecondaryNav";
import Section from "../Common/Section";

const TermAndConditionView = () => {
  return (
    <Fragment>
      <SecondaryNav
        banner={contactUsImage}
        page_name="Terms And Conditions"
        desc="Welcome to Heart Connect, the premier dating app. By using Heart Connect, you agree to our Terms and Conditions, Privacy Policy, and confirm you are at least 18 years old."
      />

      <div className="min-h-screen bg-gray-100 flex items-center pt-6 justify-center">
        <div className="p-4 w-full max-w-6xl">
          <h4 className="text-xl font-semibold mb-4 text-center">
            Welcome to Heart Connect
          </h4>
          <p className="text-gray-700 mb-6 text-center px-4">
            These Terms govern your use of Heart Connect and the Heart Connect
            applications, products, and services that we offer (the Heart
            Connect Product or Products), except where we expressly state that
            separate Terms (and not these) apply.
          </p>

          <Section
            title="1. Acceptance of Terms"
            des="By creating an account and using the Heart Connect application, you agree to comply with and be bound by these Terms and Conditions."
            colorClass="text-blue-500"
            lineColor="bg-gradient-to-r from-blue-400 to-blue-600"
            sectionArray={[
              {
                title: "Agreement",
                des: "If you do not agree to these Terms, please do not use the app.",
              },
            ]}
          />

          <Section
            title="2. Eligibility"
            des="You must be at least 18 years old to use Heart Connect."
            colorClass="text-orange-500"
            lineColor="bg-gradient-to-r from-orange-400 to-orange-600"
            sectionArray={[
              {
                title: "Age Requirement",
                des:
                  "By using the app, you represent and warrant that you meet this age requirement.",
              },
              {
                title: "Prohibited Access",
                des: "If you do not meet this age requirement, you must not access or use the app.",
              },
            ]}
          />

          <Section
            title="3. User Conduct"
            des="Understand the expectations and guidelines for user behavior while using Heart Connect."
            colorClass="text-green-500"
            lineColor="bg-gradient-to-r from-green-400 to-green-600"
            sectionArray={[
              {
                title: "Prohibited Content",
                des:
                  "You agree not to post or share any content that is offensive, obscene, defamatory, harassing, or otherwise inappropriate.",
              },
              {
                title: "Ethical Behavior",
                des:
                  "Engage in behavior that respects others and adheres to legal standards.",
              },
            ]}
          />

          <Section
            title="4. Privacy"
            des="We are committed to protecting your privacy."
            colorClass="text-purple-500"
            lineColor="bg-gradient-to-r from-purple-400 to-purple-600"
            sectionArray={[
              {
                title: "Data Collection",
                des: "Our Privacy Policy explains how we collect, use, and disclose your information.",
              },
              {
                title: "User Consent",
                des: "By using Heart Connect, you consent to our collection and use of your personal information as described in the Privacy Policy.",
              },
            ]}
          />

          <Section
            title="5. User Content"
            des="You are responsible for any content you post, upload, or share on Heart Connect."
            colorClass="text-red-500"
            lineColor="bg-gradient-to-r from-red-400 to-red-600"
            sectionArray={[
              {
                title: "Rights and Ownership",
                des:
                  "By posting User Content, you grant Heart Connect a non-exclusive, royalty-free, worldwide license to use, display, and reproduce your content.",
              },
              {
                title: "Content Compliance",
                des:
                  "Your content must comply with these Terms and all applicable laws.",
              },
            ]}
          />

          <Section
            title="6. Prohibited Activities"
            des="Understand the activities that are prohibited while using Heart Connect."
            colorClass="text-yellow-500"
            lineColor="bg-gradient-to-r from-yellow-400 to-yellow-600"
            sectionArray={[
              {
                title: "Unauthorized Access",
                des:
                  "You must not attempt to gain unauthorized access to our systems or other users' accounts.",
              },
              {
                title: "Automated Tools",
                des:
                  "Using bots, scrapers, or other automated tools to interact with the app is prohibited.",
              },
            ]}
          />

          <Section
            title="7. Termination"
            des="We reserve the right to suspend or terminate your account and access to the app."
            colorClass="text-indigo-500"
            lineColor="bg-gradient-to-r from-indigo-400 to-indigo-600"
            sectionArray={[
              {
                title: "Conditions for Termination",
                des:
                  "Your account may be terminated for violating these Terms or engaging in harmful behavior.",
              },
              {
                title: "After Termination",
                des: "Upon termination, you must cease all use of the app.",
              },
            ]}
          />

          <Section
            title="8. Limitation of Liability"
            des="Heart Connect is provided on an 'as is' and 'as available' basis."
            colorClass="text-pink-500"
            lineColor="bg-gradient-to-r from-pink-400 to-pink-600"
            sectionArray={[
              {
                title: "No Guarantees",
                des:
                  "We do not guarantee that the app will be available at all times or free of errors.",
              },
              {
                title: "Liability Limitations",
                des:
                  "Heart Connect is not liable for indirect, incidental, or punitive damages.",
              },
            ]}
          />

          <Section
            title="9. Indemnification"
            des="You agree to indemnify and hold Heart Connect harmless from any claims or damages."
            colorClass="text-teal-500"
            lineColor="bg-gradient-to-r from-teal-400 to-teal-600"
            sectionArray={[
              {
                title: "User Responsibility",
                des:
                  "You are responsible for any claims arising from your use of the app.",
              },
            ]}
          />

          <Section
            title="10. Changes to Terms"
            des="We may update these Terms from time to time."
            colorClass="text-cyan-500"
            lineColor="bg-gradient-to-r from-cyan-400 to-cyan-600"
            sectionArray={[
              {
                title: "Notification",
                des:
                  "Significant changes will be communicated through the app or via email.",
              },
              {
                title: "Continued Use",
                des:
                  "Continued use of the app after changes indicates your acceptance of the new Terms.",
              },
            ]}
          />

          <Section
            title="11. Governing Law"
            des="These Terms are governed by the laws of [Your Country/State]."
            colorClass="text-lime-500"
            lineColor="bg-gradient-to-r from-lime-400 to-lime-600"
            sectionArray={[
              {
                title: "Jurisdiction",
                des:
                  "You agree to submit to the exclusive jurisdiction of the courts in [Your Country/State].",
              },
            ]}
          />

          <Section
            title="12. Contact Us"
            des="If you have any questions about these Terms, please contact us."
            colorClass="text-gray-500"
            lineColor="bg-gradient-to-r from-gray-400 to-gray-600"
            sectionArray={[
              {
                title: "Support",
                des: "We value your feedback and are committed to addressing your concerns.",
              },
            ]}
          />

          <Section
            title="13. Miscellaneous"
            des="Additional legal provisions applicable to these Terms."
            colorClass="text-blue-800"
            lineColor="bg-gradient-to-r from-blue-600 to-blue-800"
            sectionArray={[
              {
                title: "Entire Agreement",
                des:
                  "These Terms constitute the entire agreement between you and Heart Connect regarding your use of the app.",
              },
              {
                title: "Severability",
                des:
                  "If any provision is found to be invalid, the remaining provisions will remain in effect.",
              },
              {
                title: "Waiver",
                des:
                  "Our failure to enforce any provision does not constitute a waiver of that right.",
              },
              {
                title: "Assignment",
                des:
                  "You may not transfer your rights under these Terms without our consent.",
              },
            ]}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default TermAndConditionView;













// import React, { Fragment } from "react";
// import contactUsImage from "../../Assets/contact-us.jpg";
// import { SecondaryNav } from "../Common/SecondaryNav";
// import Section from "../Common/Section";

// const TermAndConditionView = () => {
//   return (
//     <Fragment>
//       <SecondaryNav
//         banner={contactUsImage}
//         page_name="Terms And Conditions"
//         desc="Welcome to Heart Connect, the premier dating app. By using Heart Connect, you agree to our Terms and Conditions, Privacy Policy, and confirm you are at least 18 years old."
//       />

//       <div className="min-h-screen bg-gray-100 flex items-center pt-6 justify-center">
//         <div className="p-4 w-full max-w-6xl">
//           <h4 className="text-xl font-semibold mb-4 text-center">
//             Welcome to Heart Connect
//           </h4>
//           <p className="text-gray-700 mb-6 text-center px-4">
//             These Terms govern your use of Heart Connect and the Heart Connect
//             applications, products, and services that we offer (the Heart
//             Connect Product or Products), except where we expressly state that
//             separate Terms (and not these) apply.
//           </p>

//           <Section
//             title="1. Acceptance of Terms"
//             des="By creating an account and using the Heart Connect application, you agree to comply with and be bound by these Terms and Conditions."
//             colorClass="text-blue-500"
//             lineColor="bg-gradient-to-r from-blue-400 to-blue-600"
//             sectionArray={[
//               {
//                 title: "Agreement",
//                 des: "If you do not agree to these Terms, please do not use the app.",
//               },
//             ]}
//           />

//           <Section
//             title="2. Eligibility"
//             des="You must be at least 18 years old to use Heart Connect."
//             colorClass="text-orange-500"
//             lineColor="bg-gradient-to-r from-orange-400 to-orange-600"
//             sectionArray={[
//               {
//                 title: "Age Requirement",
//                 des:
//                   "By using the app, you represent and warrant that you meet this age requirement.",
//               },
//               {
//                 title: "Prohibited Access",
//                 des: "If you do not meet this age requirement, you must not access or use the app.",
//               },
//             ]}
//           />

//           <Section
//             title="3. User Conduct"
//             des="Understand the expectations and guidelines for user behavior while using Heart Connect."
//             colorClass="text-green-500"
//             lineColor="bg-gradient-to-r from-green-400 to-green-600"
//             sectionArray={[
//               {
//                 title: "Prohibited Content",
//                 des:
//                   "You agree not to post or share any content that is offensive, obscene, defamatory, harassing, or otherwise inappropriate.",
//               },
//               {
//                 title: "Ethical Behavior",
//                 des:
//                   "Engage in behavior that respects others and adheres to legal standards.",
//               },
//             ]}
//           />

//           <Section
//             title="4. Privacy"
//             des="We are committed to protecting your privacy."
//             colorClass="text-purple-500"
//             lineColor="bg-gradient-to-r from-purple-400 to-purple-600"
//             sectionArray={[
//               {
//                 title: "Data Collection",
//                 des: "Our Privacy Policy explains how we collect, use, and disclose your information.",
//               },
//               {
//                 title: "User Consent",
//                 des: "By using Heart Connect, you consent to our collection and use of your personal information as described in the Privacy Policy.",
//               },
//             ]}
//           />

//           <Section
//             title="5. User Content"
//             des="You are responsible for any content you post, upload, or share on Heart Connect."
//             colorClass="text-red-500"
//             lineColor="bg-gradient-to-r from-red-400 to-red-600"
//             sectionArray={[
//               {
//                 title: "Rights and Ownership",
//                 des:
//                   "By posting User Content, you grant Heart Connect a non-exclusive, royalty-free, worldwide license to use, display, and reproduce your content.",
//               },
//               {
//                 title: "Content Compliance",
//                 des:
//                   "Your content must comply with these Terms and all applicable laws.",
//               },
//             ]}
//           />

//           <Section
//             title="6. Prohibited Activities"
//             des="Understand the activities that are prohibited while using Heart Connect."
//             colorClass="text-yellow-500"
//             lineColor="bg-gradient-to-r from-yellow-400 to-yellow-600"
//             sectionArray={[
//               {
//                 title: "Unauthorized Access",
//                 des:
//                   "You must not attempt to gain unauthorized access to our systems or other users' accounts.",
//               },
//               {
//                 title: "Automated Tools",
//                 des:
//                   "Using bots, scrapers, or other automated tools to interact with the app is prohibited.",
//               },
//             ]}
//           />

//           <Section
//             title="7. Termination"
//             des="We reserve the right to suspend or terminate your account and access to the app."
//             colorClass="text-indigo-500"
//             lineColor="bg-gradient-to-r from-indigo-400 to-indigo-600"
//             sectionArray={[
//               {
//                 title: "Conditions for Termination",
//                 des:
//                   "Your account may be terminated for violating these Terms or engaging in harmful behavior.",
//               },
//               {
//                 title: "After Termination",
//                 des: "Upon termination, you must cease all use of the app.",
//               },
//             ]}
//           />

//           <Section
//             title="8. Limitation of Liability"
//             des="Heart Connect is provided on an 'as is' and 'as available' basis."
//             colorClass="text-pink-500"
//             lineColor="bg-gradient-to-r from-pink-400 to-pink-600"
//             sectionArray={[
//               {
//                 title: "No Guarantees",
//                 des:
//                   "We do not guarantee that the app will be available at all times or free of errors.",
//               },
//               {
//                 title: "Liability Limitations",
//                 des:
//                   "Heart Connect is not liable for indirect, incidental, or punitive damages.",
//               },
//             ]}
//           />

//           <Section
//             title="9. Indemnification"
//             des="You agree to indemnify and hold Heart Connect harmless from any claims or damages."
//             colorClass="text-teal-500"
//             lineColor="bg-gradient-to-r from-teal-400 to-teal-600"
//             sectionArray={[
//               {
//                 title: "User Responsibility",
//                 des:
//                   "You are responsible for any claims arising from your use of the app.",
//               },
//             ]}
//           />

//           <Section
//             title="10. Changes to Terms"
//             des="We may update these Terms from time to time."
//             colorClass="text-cyan-500"
//             lineColor="bg-gradient-to-r from-cyan-400 to-cyan-600"
//             sectionArray={[
//               {
//                 title: "Notification",
//                 des:
//                   "Significant changes will be communicated through the app or via email.",
//               },
//               {
//                 title: "Continued Use",
//                 des:
//                   "Continued use of the app after changes indicates your acceptance of the new Terms.",
//               },
//             ]}
//           />

//           <Section
//             title="11. Governing Law"
//             des="These Terms are governed by the laws of [Your Country/State]."
//             colorClass="text-lime-500"
//             lineColor="bg-gradient-to-r from-lime-400 to-lime-600"
//             sectionArray={[
//               {
//                 title: "Jurisdiction",
//                 des:
//                   "You agree to submit to the exclusive jurisdiction of the courts in [Your Country/State].",
//               },
//             ]}
//           />

//           <Section
//             title="12. Contact Us"
//             des="If you have any questions about these Terms, please contact us."
//             colorClass="text-gray-500"
//             lineColor="bg-gradient-to-r from-gray-400 to-gray-600"
//             sectionArray={[
//               {
//                 title: "Support",
//                 des: "We value your feedback and are committed to addressing your concerns.",
//               },
//             ]}
//           />

//           <Section
//             title="13. Miscellaneous"
//             des="Additional legal provisions applicable to these Terms."
//             colorClass="text-blue-800"
//             lineColor="bg-gradient-to-r from-blue-600 to-blue-800"
//             sectionArray={[
//               {
//                 title: "Entire Agreement",
//                 des:
//                   "These Terms constitute the entire agreement between you and Heart Connect regarding your use of the app.",
//               },
//               {
//                 title: "Severability",
//                 des:
//                   "If any provision is found to be invalid, the remaining provisions will remain in effect.",
//               },
//               {
//                 title: "Waiver",
//                 des:
//                   "Our failure to enforce any provision does not constitute a waiver of that right.",
//               },
//               {
//                 title: "Assignment",
//                 des:
//                   "You may not transfer your rights under these Terms without our consent.",
//               },
//             ]}
//           />
//         </div>
//       </div>
//     </Fragment>
//   );
// };

// export default TermAndConditionView;