import React, { Fragment } from "react";
import contactUsImage from "../../Assets/contact-us.jpg";
import { SecondaryNav } from "../Common/SecondaryNav";
import Section from "../Common/Section";

export const RefundAndCancellationView = () => {
  const sections = [
    {
      title: "1. Purchase Policy",
      des: "Digital coins can be purchased directly through the app using real money. All purchases are final and non-refundable, except where required by law.",
      colorClass: "text-blue-500",
      lineColor: "bg-gradient-to-r from-blue-400 to-blue-600",
    },
    {
      title: "2. Refund Policy",
      des: "Once digital coins are purchased, they cannot be refunded. If you encounter an issue with your purchase, please contact our support team for assistance.",
      colorClass: "text-green-500",
      lineColor: "bg-gradient-to-r from-green-400 to-green-600",
    },
    {
      title: "3. Cancellation Policy",
      des: "You may cancel your HeartConnect account at any time, but no refunds will be issued for unused digital coins.",
      colorClass: "text-red-500",
      lineColor: "bg-gradient-to-r from-red-400 to-red-600",
    },
    {
      title: "4. Dispute Resolution",
      des: "If you have any disputes related to purchases or cancellations, please reach out to our support team.",
      colorClass: "text-yellow-500",
      lineColor: "bg-gradient-to-r from-yellow-400 to-yellow-600",
    },
    {
      title: "5. Service Overview",
      des: "HeartConnect is a platform where users can connect through voice and video calls and chat using digital coins. The app is designed to foster meaningful connections in a secure environment.",
      colorClass: "text-purple-500",
      lineColor: "bg-gradient-to-r from-purple-400 to-purple-600",
      sectionArray: [
        {
          title: "Using Digital Coins",
          des: "Digital coins are required to initiate calls or send messages within the app. These coins can be purchased using real money through our secure payment gateway.",
        },
        {
          title: "Earning Coins",
          des: "Users can earn coins by engaging with other users on the platform. These earned coins can be converted to real money, subject to our coin conversion policy.",
        },
        {
          title: "Coin Conversion",
          des: "Coins earned through specific activities can be converted to real money according to our policy. This conversion process is subject to terms and conditions that may change from time to time.",
        },
        {
          title: "Customer Support",
          des: "For any questions or issues, please contact our customer support team at [insert contact information here].",
        },
      ],
    },
  ];

  return (
    <Fragment>
      <SecondaryNav
        banner={contactUsImage}
        page_name="Refund And Cancellation"
        desc="Refunds and cancellations on Heart Connect are subject to our policy. Please review the information below for details on refunds."
      />
      <div className="min-h-screen bg-gray-100 flex items-center pt-6 justify-center">
        <div className="p-4 w-full max-w-6xl">
          <h4 className="text-xl font-semibold mb-4 text-center">
            Refund and Cancellation Policy
          </h4>
          <p className="text-gray-700 mb-6 text-center px-4">
            At Heart Connect, we do not offer cancellation functionality for coin purchases. If you have made a purchase and your money has been deducted but you have not received your coins, please contact us at nihar.hec@gmail.com for a refund.
          </p>
          {sections.map((section, index) => (
            <Section
              key={index}
              title={section.title}
              des={section.des}
              colorClass={section.colorClass}
              lineColor={section.lineColor}
              sectionArray={section.sectionArray || []}
            />
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default RefundAndCancellationView;












// import React, { Fragment } from "react";
// import contactUsImage from "../../Assets/contact-us.jpg";
// import { SecondaryNav } from "../Common/SecondaryNav";
// import Section from "../Common/Section";

// export const RefundAndCancellationView = () => {
//   return (
//     <Fragment>
//       <SecondaryNav
//         banner={contactUsImage}
//         page_name="Refund And Cancellation"
//         desc="Refunds and cancellations on Heart Connect are subject to our policy. Please review the information below for details on refunds."
//       />
//       <div className="min-h-screen bg-gray-100 flex items-center pt-6 justify-center">
//         <div className="p-4 w-full max-w-6xl">
//           <h4 className="text-xl font-semibold mb-4 text-center">
//             Refund and Cancellation Policy
//           </h4>
//           <p className="text-gray-700 mb-6 text-center px-4">
//             At Heart Connect, we do not offer cancellation functionality for coin purchases. If you have made a purchase and your money has been deducted but you have not received your coins, please contact us at nihar.hec@gmail.com for a refund.
//           </p>

//           <Section
//             title="1. Refund Policy"
//             des="If your money has been deducted but you do not receive your coins, please contact us for a refund."
//             colorClass="text-blue-500"
//             lineColor="bg-gradient-to-r from-blue-400 to-blue-600"
//             sectionArray={[
//               {
//                 title: "Contact for Refund",
//                 des: "In such cases, email us at nihar.hec@gmail.com to request a refund.",
//               },
//             ]}
//           />

//           <Section
//             title="2. No Cancellation Functionality"
//             des="Please be aware that we do not support cancellation for coin purchases once the transaction is complete."
//             colorClass="text-red-500"
//             lineColor="bg-gradient-to-r from-red-400 to-red-600"
//             sectionArray={[
//               {
//                 title: "No Cancellation",
//                 des: "Once you purchase coins, they cannot be canceled or refunded unless you have not received the coins after payment.",
//               },
//             ]}
//           />
//         </div>
//       </div>
//     </Fragment>
//   );
// };
