import React from "react";
import { FaLinkedin, FaTwitter, FaFacebook } from "react-icons/fa";
import "./AboutSoftTouch.css";
import ceoImg from "../../../Assets/ceoImg.webp";

export const AboutSoftTouch = () => {
  return (
    <section className="animated-bg flex flex-col items-center py-16 px-6 md:px-12 lg:px-24 relative overflow-hidden">
      <div className="animated-card bg-white rounded-xl shadow-xl overflow-hidden flex flex-col md:flex-row w-full">

        {/* Profile Image Section */}
        <div className="flex items-center justify-center p-8 md:p-12 lg:p-16 bg-blue-50">
          <img
            src={ceoImg}
            alt="CEO of Heart Connect"
            className="min-w-60 min-h-60 md:min-w-[20rem] md:min-h-[20rem] max-w-60 max-h-60 md:max-w-[20rem] md:max-h-[20rem] rounded-full shadow-lg"
          />
        </div>

        {/* Info Section */}
        <div className="flex flex-col justify-center p-8 md:p-12 lg:p-16 text-center md:text-left">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800">
            Dr. Nihar Ranjan Behera
          </h2>
          <p className="text-lg text-gray-500 font-medium mt-1">
            CEO & Founder, Heart Connect
          </p>

          <p className="text-gray-700 mt-6 text-justify leading-relaxed text-base md:text-lg">
            Dr. Nihar Ranjan Behera combines a unique vision for human connection with innovation. His leadership has transformed Heart Connect into a trusted platform, helping millions build meaningful relationships worldwide.
          </p>

          <div className="flex justify-center md:justify-start space-x-6 mt-10 text-gray-500">
            <a
              href="https://linkedin.com/in/ceo-link"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-600 transition-colors"
            >
              <FaLinkedin size={28} />
            </a>
            <a
              href="https://twitter.com/ceo-handle"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-400 transition-colors"
            >
              <FaTwitter size={28} />
            </a>
            <a
              href="https://facebook.com/ceo-profile"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-800 transition-colors"
            >
              <FaFacebook size={28} />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
