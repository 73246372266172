import React, { Fragment } from "react";
import contactUsImage from "../../Assets/contact-us.jpg";
import { SecondaryNav } from "../Common/SecondaryNav";
import Section from "../Common/Section";

export const PrivacyPolicyView = () => {
  return (
    <Fragment>
      <SecondaryNav
        banner={contactUsImage}
        page_name="Privacy Policy"
        desc="By using Heart Connect, you agree to our Privacy Policy, which details how we collect, use, and protect your personal information."
      />

      <div className="min-h-screen bg-gray-100 flex items-center pt-6 justify-center">
        <div className="p-4 w-full max-w-6xl">
          <h4 className="text-xl font-semibold mb-4 text-center tracking-wider">
            Welcome to Heart Connect
          </h4>
          <p className="text-gray-700 mb-6 text-center px-4 tracking-wide">
            At Heart Connect, we recognize the importance of your privacy and
            are committed to protecting the personal data you share with us.
            This privacy policy outlines the types of data we collect, how we
            use it, and the measures we take to safeguard your information.
          </p>

          <Section
            title="Privacy Policy"
            des="We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how HeartConnect collects, uses, and safeguards your data."
            colorClass="text-blue-500"
            lineColor="bg-gradient-to-r from-blue-400 to-blue-600"
            sectionArray={[
              {
                title: "Data Collection",
                des: [
                  "We collect certain personal data to enhance your experience, including your mobile number, email ID, and location. This information is required for authentication and login purposes.",
                ],
              },
              {
                title: "Usage of Data",
                des: [
                  "Your data is used to verify your identity, provide our services, and improve user experience. We do not share your data with third parties without your consent, except as required by law.",
                ],
              },
              {
                title: "Data Sharing",
                des: [
                  "We may share your data with trusted partners only for the purpose of providing our services, such as third-party authentication services.",
                ],
              },
              {
                title: "Security Measures",
                des: [
                  "We implement industry-standard security measures to protect your data from unauthorized access or disclosure.",
                ],
              },
              {
                title: "Changes to Policy",
                des: [
                  "Any changes to our Privacy Policy will be communicated to you through the app or via email.",
                ],
              },
            ]}
          />

          <Section
            title="Data Collection and Usage"
            des="HeartConnect collects information like your mobile number, email ID, and location for account creation, authentication, and providing personalized services. We use this data to improve our app's functionality, offer tailored features, and enhance user experience."
            colorClass="text-orange-500"
            lineColor="bg-gradient-to-r from-orange-400 to-orange-600"
            sectionArray={[
              {
                title: "",
                des: [
                  "Login Data: Information such as your username, email address, and password when you create an account.",
                  "Profile Building Data: Details you provide to complete your profile, including your name, profile picture, bio, interests, and other personal information.",
                  "Content You Share: Any posts, videos, comments, or other content you share on our platform.",
                  "Log Data: Information about your interactions with our app, such as IP address, browser type, pages visited, and timestamps.",
                  "Cookies: Small files stored on your device that help us enhance your user experience and understand how you use our app.",
                  "User Search Data: Information about your search queries within our app.",
                  "Account Security: Data related to the security of your account, such as two-factor authentication details.",
                  "Contact Information Access: Access to your contact list to help you connect with friends and acquaintances.",
                  "Location Data: Information about your geographic location when you use our app.",
                ],
              },
            ]}
          />

          <Section
            title="Data Sharing"
            des="We may share your information with trusted service providers who assist us in operating the app, such as payment processors. We ensure that these partners adhere to strict confidentiality obligations. We will only share your information in compliance with legal requirements or to protect our rights."
            colorClass="text-purple-500"
            lineColor="bg-gradient-to-r from-purple-400 to-purple-600"
            sectionArray={[
              {
                title: "",
                des: [
                  "We do not sell your personal data to third parties.",
                  "We may share your data with trusted partners and service providers who assist us in operating our platform, subject to strict confidentiality agreements.",
                  "We may also share your data when required by law or to protect our rights and users.",
                ],
              },
            ]}
          />

          <Section
            title="User Rights and Security"
            des="You have the right to access, update, or delete your personal information by contacting our support team. We implement robust security measures to protect your data from unauthorized access, loss, or misuse."
            colorClass="text-green-500"
            lineColor="bg-gradient-to-r from-green-400 to-green-600"
            sectionArray={[
              {
                title: "",
                des: [
                  "We implement robust security measures to protect your data from unauthorized access, disclosure, alteration, and destruction. This includes encryption, access controls, and regular security audits.",
                  "We also encourage you to use strong passwords and enable two-factor authentication to further safeguard your account.",
                ],
              },
            ]}
          />

          <Section
            title="Policy Updates"
            des="Our Privacy Policy may change over time to reflect new practices or legal requirements. We will notify you of any significant changes through the app or via email."
            colorClass="text-indigo-500"
            lineColor="bg-gradient-to-r from-indigo-400 to-indigo-600"
            sectionArray={[
              {
                title: "",
                des: [
                  "We may update this privacy policy from time to time.",
                  "We will notify you of any significant changes by posting the new policy on our app and, where appropriate, through other communication channels.",
                ],
              },
            ]}
          />

          <Section
            title="Contact Us"
            des="If you have any questions about this privacy policy or our data practices, please contact us."
            colorClass="text-pink-500"
            lineColor="bg-gradient-to-r from-pink-400 to-pink-600"
            sectionArray={[
              {
                title: "",
                des: [
                  "We Heart Connect to ",
                  "Email : nihar.hec@gmail.com",
                ],
              },
            ]}
          />
        </div>
      </div>
    </Fragment>
  );
};












// import React, { Fragment } from "react";
// import contactUsImage from "../../Assets/contact-us.jpg";
// import { SecondaryNav } from "../Common/SecondaryNav";
// import Section from "../Common/Section";

// export const PrivacyPolicyView = () => {
//   return (
//     <Fragment>
//       <SecondaryNav
//         banner={contactUsImage}
//         page_name="Privacy Policy"
//         desc="By using Heart Connect, you agree to our Privacy Policy, which details how we collect, use, and protect your personal information."
//       />

//       <div className="min-h-screen bg-gray-100 flex items-center pt-6 justify-center">
//         <div className="p-4 w-full max-w-6xl">
//           <h4 className="text-xl font-semibold mb-4 text-center tracking-wider">
//             Welcome to Heart Connect
//           </h4>
//           <p className="text-gray-700 mb-6 text-center px-4 tracking-wide">
//             At We Heart Connect, we recognize the importance of your privacy and are committed to protecting the personal data you share with us. This privacy policy outlines the types of data we collect, how we use it, and the measures we take to safeguard your information.
//           </p>

//           <Section
//             title="1. Data We Collect"
//             des="Learn about the types of information we collect when you use Heart Connect and how we use that information."
//             colorClass="text-blue-500"
//             lineColor="bg-gradient-to-r from-blue-400 to-blue-600"
//             sectionArray={[
//               {
//                 title: "",
//                 des: [
//                   "Login Data: Information such as your username, email address, and password when you create an account.",
//                   "Profile Building Data: Details you provide to complete your profile, including your name, profile picture, bio, interests, and other personal information.",
//                   "Content You Share: Any posts, videos, comments, or other content you share on our platform.",
//                   "Log Data: Information about your interactions with our app, such as IP address, browser type, pages visited, and timestamps.",
//                   "Cookies: Small files stored on your device that help us enhance your user experience and understand how you use our app.",
//                   "User Search Data: Information about your search queries within our app.",
//                   "Account Security: Data related to the security of your account, such as two-factor authentication details.",
//                   "Contact Information Access: Access to your contact list to help you connect with friends and acquaintances.",
//                   "Location Data: Information about your geographic location when you use our app."
//                 ],
//               },
//             ]}
//           />

//           <Section
//             title="2. How We Use Your Data"
//             des="Understand how we use the information we collect to provide, maintain, and improve our services."
//             colorClass="text-orange-500"
//             lineColor="bg-gradient-to-r from-orange-400 to-orange-600"
//             sectionArray={[
//               {
//                 title: "",
//                 des: [
//                   "Account Management: To create and manage your user account, including authenticating your identity and maintaining your profile.",
//                   "Personalization: To tailor content and recommendations based on your preferences, interests, and activity on the app.",
//                   "Content Sharing: To enable you to share posts, videos, and other content with other users and to facilitate interactions on the platform.",
//                   "Improvement and Development: To analyze usage patterns and feedback to improve our services, develop new features, and enhance user experience.",
//                   "Security and Safety: To monitor and maintain the security of our platform, protect against unauthorized access, and ensure the safety of our users.",
//                   "Communication: To send you important updates, notifications, and promotional materials, and to respond to your inquiries and support requests.",
//                   "Advertising: To display relevant advertisements and measure their effectiveness based on your activity and preferences.",
//                   "Compliance: To comply with legal obligations and protect our legal rights."
//                 ],
//               },
//             ]}
//           />

//           <Section
//             title="3. Data Protection"
//             des="Learn about the measures we take to protect your personal information and ensure its confidentiality."
//             colorClass="text-green-500"
//             lineColor="bg-gradient-to-r from-green-400 to-green-600"
//             sectionArray={[
//               {
//                 title: "",
//                 des: [
//                   "We implement robust security measures to protect your data from unauthorized access, disclosure, alteration, and destruction. This includes encryption, access controls, and regular security audits.",
//                   "We also encourage you to use strong passwords and enable two-factor authentication to further safeguard your account."
//                 ],
//               },
//             ]}
//           />

//           <Section
//             title="4. Data Sharing"
//             des="Learn about the circumstances under which we share your personal data and with whom."
//             colorClass="text-purple-500"
//             lineColor="bg-gradient-to-r from-purple-400 to-purple-600"
//             sectionArray={[
//               {
//                 title: "",
//                 des: [
//                   "We do not sell your personal data to third parties.",
//                   "We may share your data with trusted partners and service providers who assist us in operating our platform, subject to strict confidentiality agreements.",
//                   "We may also share your data when required by law or to protect our rights and users."
//                 ],
//               },
//             ]}
//           />

//           <Section
//             title="5. Your Rights"
//             des="Understand your rights regarding your personal data and how to exercise them."
//             colorClass="text-red-500"
//             lineColor="bg-gradient-to-r from-red-400 to-red-600"
//             sectionArray={[
//               {
//                 title: "",
//                 des: [
//                   "You have the right to access, correct, or delete your personal data.",
//                   "You can manage your data preferences and settings within your account.",
//                   "If you have any questions or concerns about your privacy, please contact us at [contact email/phone number]."
//                 ],
//               },
//             ]}
//           />

//           <Section
//             title="6. Changes to This Policy"
//             des="Learn about how we will notify you of changes to this privacy policy."
//             colorClass="text-indigo-500"
//             lineColor="bg-gradient-to-r from-indigo-400 to-indigo-600"
//             sectionArray={[
//               {
//                 title: "",
//                 des: [
//                   "We may update this privacy policy from time to time.",
//                   "We will notify you of any significant changes by posting the new policy on our app and, where appropriate, through other communication channels."
//                 ],
//               },
//             ]}
//           />

//           <Section
//             title="7. Contact Us"
//             des="If you have any questions about this privacy policy or our data practices, please contact us."
//             colorClass="text-pink-500"
//             lineColor="bg-gradient-to-r from-pink-400 to-pink-600"
//             sectionArray={[
//               {
//                 title: "",
//                 des: [
//                   "We Heart Connect to ",
//                   "Email : nihar.hec@gmail.com"
//                 ],
//               },
//             ]}
//           />
//         </div>
//       </div>
//     </Fragment>
//   );
// };
