/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { HiOutlineMail } from "react-icons/hi";
import { CiLocationOn, CiMail } from "react-icons/ci";
import { MdOutlineDialerSip } from "react-icons/md";

export const GetInTouch = () => {
  return (
    <div className="flex flex-col lg:flex-row justify-between w-full space-y-4 md:space-y-0 md:space-x-4 py-8 px-3 md:py-24 md:px-36 gap-4">
      {/* <!-- left side --> */}
      <div className=" w-full  p-4">
        <h4 className="text-[#CE3780]">Contact Us</h4>
        <div className="border border-[#CE3780] w-9 my-2"></div>
        <h2 className="text-2xl md:text-3xl lg:text-4xl">Get In Touch</h2>
        <p className="mt-2">
        We'd love to hear from you! Whether you have a question, feedback, or need assistance, feel free to reach out to us. Our team is here to help and ensure you have the best experience with Heart Connect.
        </p>
        {/* <!-- cards --> */}
        <div className="block 2xl:flex lg:gap-4 ">
          <div className="w-full bg-white border rounded-lg shadow-lg mt-6 dark:bg-gray-800 dark:border-gray-700 mx-auto">
            <div className="p-6 text-center">
              <div className="flex flex-col gap-4jll">
                <span className="flex items-center gap-2">
                  <CiMail size={22} color="#CE3780" />nihar.hec@gmail.com
                </span>
                {/* <span className="flex items-center gap-2">
                  <MdOutlineDialerSip size={22} color="#CE3780" />
                  +91 99376 38444
                </span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- right side --> */}
      <div className="w-full  max-w-2xl mx-auto  ">
        <form
          action="https://demo.waheeddev.com/soft-touch/wp-json/metform/v1/entries/insert/341"
          method="post"
          className="space-y-4 bg-white border rounded-lg shadow-lg px-8 py-12 dark:bg-gray-800 dark:border-gray-700"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <input
                type="text"
                name="mf-first-name"
                placeholder="First name here"
                className="w-full p-3 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                aria-invalid="false"
              />
            </div>
            <div>
              <input
                type="text"
                name="mf-last-name"
                placeholder="Last name here"
                className="w-full p-3 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                aria-invalid="false"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <input
                type="email"
                name="mf-email"
                placeholder="Add email"
                className="w-full p-3 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                aria-invalid="false"
              />
            </div>
            <div>
              <input
                type="text"
                name="mf-subject"
                placeholder="How can we help you?"
                className="w-full p-3 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                aria-invalid="false"
              />
            </div>
          </div>
          <div>
            <textarea
              name="mf-comment"
              placeholder="Comments"
              className="w-full p-6 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              rows="4"
              aria-invalid="false"
            ></textarea>
          </div>
          <div className="text-center">
            <button className="transform duration-300 ease-in-out flex items-center bg-[#c51369] text-[#fdfdfd] hover:text-[#c51369] hover:bg-[#fdfdfd] hover:shadow-md hover:shadow-[#9e788a] px-4 py-2 focus:outline-none font-semibold tracking-wider rounded-bl-3xl rounded-tr-3xl">
              <HiOutlineMail size={20} />
              &nbsp;Send a Message
            </button>
          </div>
          <div
            className="hidden mf-main-response-wrap mf-response-msg-wrap"
            data-show="0"
          >
            <div className="mf-response-msg">
              <i className="mf-success-icon fas fa-check"></i>
              <p></p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
