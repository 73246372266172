import React from 'react'
import { SecondaryNav } from '../../Components/Common/SecondaryNav'
import abtImage from "../../Assets/contact-us.jpg";
import DownloadedApp from '../../Components/Home/DownloadedApp';
import CardSection from '../../Components/Home/CardSection';
import Testimonial from '../../Components/Home/Testimonial';

const Services = () => {
  return (
    <React.Fragment>
      <SecondaryNav
        banner={abtImage}
        page_name="Services"
        desc="Welcome to ❤️Heart Connect❤️, where relationships bloom! Swipe, match, and chat with local singles who share your interests and values!"
      />

      <DownloadedApp />
      <CardSection />
      <Testimonial />
    </React.Fragment>
  )
}

export default Services
