import React from "react";
import { OurApproach } from "../../Components/AboutUs/OurApproach";
import { OurTeam } from "../../Components/AboutUs/OurTeam";
// import { Newsletter } from "../../Components/AboutUs/Newsletter";
import { AboutSoftTouch } from "../../Components/AboutUs/AboutSoftTouch";
import { SecondaryNav } from "../../Components/Common/SecondaryNav";
import abtImage from "../../Assets/contact-us.jpg";

const About = () => {
  return (
    <React.Fragment>
      <SecondaryNav
        banner={abtImage}
        page_name="About Us"
        desc="At Heart Connect, we believe in the power of meaningful connections. Our mission is to bring people together through authentic and engaging experiences, helping you find love, friendship, and everything in between"
      />
      <AboutSoftTouch />
      <OurApproach />
      <OurTeam />
      {/* <Newsletter /> */}
    </React.Fragment>
  );
};

export default About;
