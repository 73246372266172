import React from 'react';
import overlayImage from '../../Assets/couple2.png';
import cicon1 from '../../Assets/cicon1.svg';
import cicon2 from '../../Assets/cicon2.svg';
import cicon3 from '../../Assets/cicon3.svg';
import cicon4 from '../../Assets/cicon4.svg';

const TrustSite = () => {
    return (
        <div className="flex flex-col md:flex-row w-full">
            {/* First Column */}
            <div className="flex flex-col justify-center items-center w-full md:w-1/2 lg:w-1/4 p-4 space-y-8">
                <div className="flex flex-col items-center text-center">
                    <img
                        data-aos="zoom-out-up"
                        src={cicon2}
                        alt="icon"
                        className="h-16 m-auto"
                    />
                    <h2 data-aos="flip-right" className="text-xl lg:text-2xl font-semibold text-[#0d0b0b] tracking-wider my-2">
                        Awesome Community
                    </h2>
                    <p data-aos="flip-down" className="text-gray-600 text-md lg:text-lg tracking-wide">
                        Join an amazing community where connections thrive and friendships flourish.
                    </p>
                </div>
                <div className="flex flex-col items-center text-center">
                    <img
                        data-aos="zoom-out-up"
                        src={cicon4}
                        alt="icon"
                        className="h-16 m-auto"
                    />
                    <h2 data-aos="flip-right" className="text-xl lg:text-2xl font-semibold text-[#0d0b0b] tracking-wider my-2">
                        Join Community
                    </h2>
                    <p data-aos="flip-down" className="text-gray-600 text-md lg:text-lg tracking-wide">
                        Become a part of a vibrant community and connect with like-minded individuals.
                    </p>
                </div>
            </div>

            {/* Second Column */}
            <div className="flex justify-center items-center w-full md:w-1/2">
                <img
                    data-aos="zoom-out-down"
                    src={overlayImage}
                    alt="Overlay"
                    className={`h-[250px] md:h-[80%] object-contain`}
                />
            </div>

            {/* Third Column */}
            <div className="flex flex-col justify-center items-center w-full md:w-1/2 lg:w-1/4 p-4 space-y-8">
                <div className="flex flex-col items-center text-center">
                    <img
                        data-aos="zoom-out-up"
                        src={cicon3}
                        alt="icon"
                        className="h-16 m-auto"
                    />
                    <h2 data-aos="flip-right" className="text-xl lg:text-2xl font-semibold text-[#0d0b0b] tracking-wider my-2">
                        Private Groups
                    </h2>
                    <p data-aos="flip-down" className="text-gray-600 text-md lg:text-lg tracking-wide">
                        Join exclusive groups to connect with others in a private, engaging environment.
                    </p>
                </div>
                <div className="flex flex-col items-center text-center">
                    <img
                        data-aos="zoom-out-up"
                        src={cicon1}
                        alt="icon"
                        className="h-16 m-auto"
                    />
                    <h2 data-aos="flip-right" className="text-xl lg:text-2xl font-semibold text-[#0d0b0b] tracking-wider my-2">
                        Friendly Forums
                    </h2>
                    <p data-aos="flip-down" className="text-gray-600 text-md lg:text-lg tracking-wide">
                        Engage in welcoming forums where conversations are open and supportive.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TrustSite;